/* eslint-disable no-plusplus */
import { AxiosResponse } from 'axios';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useFlagsContext, usePromoCodeContext } from '../../contexts';
import { DayPassSelection, useDayPassSelection } from '../DayPassSelection';
import { StyledHeading } from '../MembershipPanel/MembershipPanel.styled';
import { PinoProducts } from '../PinoProducts';
import { Tabs } from '../Tabs';
import { TabItem } from '../Tabs/Tabs.types';
import { DayPassesPanel } from './DayPassesPanel';
import {
    StyledDescription,
    StyledHeaderSection,
    StyledWrapper,
} from './Membership.styled';
import { MembershipProperties } from './Membership.types';
import { StudentPanel } from './StudentPanel';
import { MonthlyPanels } from './panels';
import {
    OptimizelyFeatureFlags,
    Product,
    ProductVariant,
    TggMembershipType,
    daypassTabParameterValue,
    membershipTabParameterName,
    studentTabParameterValue,
} from '@tgg/common-types';
import { axiosInstance } from '@tgg/micro-services/axios';
import {
    getPinoProducts,
    hasPinoProduct,
    relativeUrlWithBasePath,
} from '@tgg/util';

/**
 * Note: When removing the original Membership and renaming this, make sure to bring over the schema file
 * It's not included here as we don't want to affect the Amplience pipeline scripts
 */
export function Membership({
    id,
    text = '',
    description = '',
    ctaHref = '',
    products,
    selectedProductId,
    onSelect,
    productsAvailableAddons,
    gymName = '',
    openingHours,
    isKickerOffersRibbonEnabled,
    offSale = false,
    branchId,
    ctaName,
    productHighlightPills,
}: MembershipProperties) {
    const router = useRouter();
    const {
        Monthly: monthlyProducts,
        Fixed: fixedProducts,
        Student: studentProducts,
        DayPass: dayPassProducts,
    } = products;
    const doIt = monthlyProducts[TggMembershipType.Do_It];
    const liveIt = monthlyProducts[TggMembershipType.Live_It];
    const liveItFixed = fixedProducts.find(
        product => product.membershipType === TggMembershipType.Live_It_Fixed,
    );
    const areAnyMonthly = doIt || liveIt;
    const uniqueId = 'membership-types';

    const {
        flags: {
            [OptimizelyFeatureFlags.MEMBERSHIP_TERMS_VISIBILITY]: {
                enabled: isVisibleTermsFlagEnabled,
                variationKey: visibleTermsValidationKey,
            },
            [OptimizelyFeatureFlags.SHORTEN_MBT_PRICE_DRAW_TEST]: {
                enabled: isShortenMBTPriceDrawFlagEnabled,
                variationKey: shortenMBTValidationKey,
            },
            [OptimizelyFeatureFlags.PAYPAL_PAY_IN_3_PRODUCT_OPTIONS]: {
                enabled: isPaypalPayIn3Enabled,
                variationKey: isPaypalPayIn3EnabledVariationKey,
            },
            [OptimizelyFeatureFlags.PAYPAL_PAY_IN_3_TABLE_IFRAME]: {
                enabled: isPaypalIframeEnabled,
                variationKey: isPaypalIframeEnabledVariationKey,
            },
            [OptimizelyFeatureFlags.PRODUCT_TABLE_FREE_FREEZE_NOTICE]: {
                enabled: isFreeFreezeNoticeEnabled,
                variationKey: isFreeFreezeNoticeEnabledVariationKey,
            },
            [OptimizelyFeatureFlags.GUEST_PASS_ENABLED]: {
                enabled: isGuestPassFlagEnabled,
                variationKey: isGuestPassFlagEnabledVariationKey,
            },
            [OptimizelyFeatureFlags.PINO_TABLE_MBT_OPTIMIZATION]: {
                enabled: isOptimizationEnabled,
                variationKey: optimizationVariationKey,
            },
        },
    } = useFlagsContext();

    const { handleDayPassProductSelection, ...dayPassSelectionProperties } =
        useDayPassSelection();

    const isMBTOptimizationEnabled =
        isOptimizationEnabled && optimizationVariationKey === 'v1';

    // Open date picker if changeDate is present in the url params
    useEffect(() => {
        if (router.query.changeDate) {
            // eslint-disable-next-line no-void
            void router.replace(router.pathname);

            const currentProduct = products[ProductVariant.DayPass]?.find(
                p => p.id === selectedProductId,
            );

            if (currentProduct) {
                handleDayPassProductSelection(currentProduct);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let finalMonthlyProducts = [];

    if (areAnyMonthly) {
        const monthlyProductData = Object.values(monthlyProducts as Product);
        finalMonthlyProducts = monthlyProductData;
    }

    const legacyFixedProducts = fixedProducts.filter(
        product => product.classification === 'Legacy',
    );

    if (legacyFixedProducts.length > 0) {
        finalMonthlyProducts = [
            ...finalMonthlyProducts,
            ...legacyFixedProducts,
        ];
    }

    const isPinoProduct = hasPinoProduct(monthlyProducts);

    const membershipPinoProducts = getPinoProducts(products);

    let index = isPinoProduct || areAnyMonthly ? 1 : 0; // if there is a Pino/Monthly tab then the index is 1
    const studentTabIndex = studentProducts.length > 0 ? index++ : null;
    const dayPassTabIndex = dayPassProducts.length > 0 ? index : null;

    const [openTabIndex, setOpenTabIndex] = useState<number>(0);

    const defineOpenedTab = (preselectedMembership: ProductVariant) => {
        /* istanbul ignore else - covered by tests */
        if (
            preselectedMembership &&
            preselectedMembership === ProductVariant.Student &&
            studentTabIndex !== null
        ) {
            setOpenTabIndex(studentTabIndex);
        } else if (
            preselectedMembership &&
            preselectedMembership === ProductVariant.DayPass &&
            dayPassTabIndex !== null
        ) {
            setOpenTabIndex(dayPassTabIndex);
        } else if (preselectedMembership) {
            setOpenTabIndex(0); // default to first tab
        }
    };

    const { code: promoCodeFromContext } = usePromoCodeContext();

    // set preselected membership tab
    useEffect(() => {
        // get from url query
        // eslint-disable-next-line compat/compat
        const queries = new URLSearchParams(router.asPath.split('?')[1]);
        const membershipTab = queries.get(membershipTabParameterName);
        /* istanbul ignore else - covered by tests */
        if (membershipTab && membershipTab === studentTabParameterValue) {
            defineOpenedTab(ProductVariant.Student);
        } else if (
            membershipTab &&
            membershipTab === daypassTabParameterValue
        ) {
            defineOpenedTab(ProductVariant.DayPass);
        } else if (membershipTab) {
            defineOpenedTab(ProductVariant.None);
        }

        const wrapperFunction = async (branch: string, code: string) => {
            const membershipVariant = await getMembershipVariantByPromoCode(
                branch,
                code,
            );

            /* istanbul ignore else */
            if (membershipVariant) {
                defineOpenedTab(membershipVariant);
            }
        };
        // get from promoCodeContext
        /* istanbul ignore else */
        if (promoCodeFromContext) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            wrapperFunction(branchId, promoCodeFromContext);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady, promoCodeFromContext]);

    if (offSale) return null;

    const areTermsVisible =
        (isVisibleTermsFlagEnabled && visibleTermsValidationKey === 'V1') ||
        (isShortenMBTPriceDrawFlagEnabled &&
            shortenMBTValidationKey === 'v1_stdmbt_opd') ||
        (isShortenMBTPriceDrawFlagEnabled &&
            shortenMBTValidationKey === 'v3_shortmbt_opd');
    const isBenefitsShortened =
        (isShortenMBTPriceDrawFlagEnabled &&
            shortenMBTValidationKey === 'v2_shortmbt_cpd') ||
        (isShortenMBTPriceDrawFlagEnabled &&
            shortenMBTValidationKey === 'v3_shortmbt_opd');
    const isPaypalIframeVisible =
        isPaypalIframeEnabled && isPaypalIframeEnabledVariationKey === 'on';
    const isPaypalPayIn3Visible =
        isPaypalPayIn3Enabled && isPaypalPayIn3EnabledVariationKey === 'on';
    const isGuestPassEnabled =
        isGuestPassFlagEnabled && isGuestPassFlagEnabledVariationKey === 'on';
    const isFreeFreezeNoticeVisible =
        isFreeFreezeNoticeEnabled &&
        isFreeFreezeNoticeEnabledVariationKey === 'on';

    return (
        <>
            <StyledWrapper id={id ?? 'membership-table'}>
                <StyledHeaderSection>
                    {text && !isPinoProduct ? (
                        <StyledHeading variant="h2">{text}</StyledHeading>
                    ) : null}
                    {description && (
                        <StyledDescription variant="body1">
                            {description}
                        </StyledDescription>
                    )}
                </StyledHeaderSection>
                <Tabs
                    gymName={gymName}
                    items={
                        [
                            isPinoProduct
                                ? {
                                      label: 'memberships',
                                      content: (
                                          <PinoProducts
                                              products={membershipPinoProducts}
                                              gymName={gymName}
                                              ctaHref={ctaHref}
                                              productsAvailableAddons={
                                                  productsAvailableAddons
                                              }
                                              openingHours={openingHours}
                                              onSelect={onSelect}
                                              isKickerOffersRibbonEnabled={
                                                  isKickerOffersRibbonEnabled
                                              }
                                              areTermsVisible={areTermsVisible}
                                              productHighlightPills={
                                                  productHighlightPills
                                              }
                                              isBenefitsShortened={
                                                  isBenefitsShortened
                                              }
                                              isGuestPassEnabled={
                                                  isGuestPassEnabled
                                              }
                                              ctaName={ctaName}
                                              isPaypalIframeVisible={
                                                  isPaypalIframeVisible
                                              }
                                              isPaypalPayIn3Visible={
                                                  isPaypalPayIn3Visible
                                              }
                                              isFreeFreezeNoticeVisible={
                                                  isFreeFreezeNoticeVisible
                                              }
                                              isMBTOptimizationEnabled={
                                                  isMBTOptimizationEnabled
                                              }
                                          />
                                      ),
                                      products: membershipPinoProducts.Monthly,
                                  }
                                : false,
                            areAnyMonthly && !isPinoProduct
                                ? {
                                      label: liveItFixed
                                          ? 'Memberships'
                                          : 'Monthly',
                                      content: (
                                          <MonthlyPanels
                                              liveIt={liveIt}
                                              doIt={doIt}
                                              liveItFixed={liveItFixed}
                                              gymName={gymName}
                                              ctaHref={ctaHref}
                                              onSelect={onSelect}
                                              productsAvailableAddons={
                                                  productsAvailableAddons
                                              }
                                          />
                                      ),
                                      products: finalMonthlyProducts,
                                  }
                                : false,
                            studentProducts.length > 0
                                ? {
                                      label: 'Student',
                                      content: (
                                          <StudentPanel
                                              products={membershipPinoProducts}
                                              gymName={gymName}
                                              ctaHref={ctaHref}
                                              productsAvailableAddons={
                                                  productsAvailableAddons
                                              }
                                              openingHours={openingHours}
                                              onSelect={onSelect}
                                              areTermsVisible={areTermsVisible}
                                              productHighlightPills={
                                                  productHighlightPills
                                              }
                                              isBenefitsShortened={
                                                  isBenefitsShortened
                                              }
                                              ctaName={ctaName}
                                              isPaypalIframeVisible={
                                                  isPaypalIframeVisible
                                              }
                                              isPaypalPayIn3Visible={
                                                  isPaypalPayIn3Visible
                                              }
                                              isMBTOptimizationEnabled={
                                                  isMBTOptimizationEnabled
                                              }
                                          />
                                      ),
                                      products: studentProducts,
                                  }
                                : false,
                            dayPassProducts.length > 0
                                ? {
                                      label: 'Day Passes',
                                      content: (
                                          <DayPassesPanel
                                              areAnyMonthly={!!areAnyMonthly}
                                              products={
                                                  membershipPinoProducts.DayPass
                                              }
                                              gymName={gymName}
                                              onSelect={
                                                  handleDayPassProductSelection
                                              }
                                              productHighlightPills={
                                                  productHighlightPills
                                              }
                                              openingHours={openingHours}
                                          />
                                      ),
                                      products: dayPassProducts,
                                  }
                                : false,
                        ].filter(item => item) as TabItem[]
                    }
                    tabGroupAriaLabel="Membership Types"
                    uniqueId={uniqueId}
                    defaultSelectedIndex={openTabIndex}
                />
            </StyledWrapper>
            <DayPassSelection
                onSelect={onSelect}
                {...dayPassSelectionProperties}
            />
        </>
    );
}

export default Membership;

const getMembershipVariantByPromoCode = async (
    branchId: string,
    promoCode: string,
) => {
    const response = await axiosInstance.get<
        { branchId: string; promoCode: string },
        AxiosResponse<string>
    >(relativeUrlWithBasePath('/api/proxy/gyms/membershiptypes/'), {
        params: {
            branchId,
            promoCode,
        },
    });

    return ProductVariant[response.data as keyof typeof ProductVariant];
};
