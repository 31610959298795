/* istanbul ignore file */
import { Card, CardProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { HorizontalRule } from '../HorizontalRule';
import { PinoButton } from '../PinoButton';
import { CardOrientation } from './PinoProductOption.types';

interface StyledPriceContainerProperties {
    $wrap?: boolean;
}

export const StyledCardHeader = styled('header')<{
    $isMBTOptimizationEnabled: boolean;
}>`
    display: flex;
    flex-direction: 'row';
    align-items: flex-start;
    font-size: 1.2rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: ${({ $isMBTOptimizationEnabled }) =>
            $isMBTOptimizationEnabled ? 'column' : 'row'};
        font-size: 1.5rem;
    }
`;

export const StyledPriceContainer = styled('div')`
    flex-direction: column;
    font-size: 1.2rem;
`;

interface StyledPriceProperties {
    $color: string;
}

export const StyledPrice = styled('div')<StyledPriceProperties>`
    font-size: 1.9rem;
    font-family: thegymgroupsans-Headline, sans-serif;

    ${({ $color }) => `color: ${$color};`}

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        display: inline;
        font-size: 2.2rem;
    }
`;

export const StyledStrikethroughPrice = styled('p')<{
    $isMBTOptimizationEnabled?: boolean;
}>`
    margin: ${({ $isMBTOptimizationEnabled }) =>
            $isMBTOptimizationEnabled ? '-0.2rem' : '0'}
        0.4rem 0 0;
    padding: 0;
    font-size: ${({ $isMBTOptimizationEnabled }) =>
        $isMBTOptimizationEnabled ? '1rem' : '1.5rem'};
    text-decoration: line-through;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: ${({ $isMBTOptimizationEnabled }) =>
            $isMBTOptimizationEnabled && '1.2rem'};
    }
`;

export const StyledBoldText = styled('b')<{
    $isMBTOptimizationEnabled?: boolean;
}>`
    padding-bottom: ${({ $isMBTOptimizationEnabled }) =>
        $isMBTOptimizationEnabled && '1rem'};
    font-weight: 400;
    font-family: thegymgroupsans-Headline, sans-serif;
`;

export const StyledBoldHeader = styled('b')<{
    $isMBTOptimizationEnabled?: boolean;
}>`
    padding-bottom: ${({ $isMBTOptimizationEnabled }) =>
        $isMBTOptimizationEnabled && '1rem'};
    font-weight: 400;
    font-family: thegymgroupsans-Headline, sans-serif;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: ${({ $isMBTOptimizationEnabled }) =>
            $isMBTOptimizationEnabled && '1.8rem'};
    }
`;

export const StyledPinoOptionButton = styled(PinoButton)<{
    $isMBTOptimizationEnabled?: boolean;
}>`
    width: auto;
    margin-top: ${({ $isMBTOptimizationEnabled }) =>
        $isMBTOptimizationEnabled && '1rem'};
    font-size: 1.4rem;

    svg g {
        fill: ${palette.common.white};
    }

    ${({ selected }) => {
        if (selected) {
            return `
                padding: 0.4rem 1rem;
            `;
        }

        return `padding: 0.1rem 1rem;`;
    }}
`;

interface StyledCardProperties extends CardProps {
    $pinoPrimary: string;
    $pinoAlternate: string;
    $orientation: CardOrientation;
    $numberOfElements: number;
    selected: boolean;
    $isMBTOptimizationEnabled: boolean;
}

export const StyledCard = styled(Card, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<StyledCardProperties>`
    ${({ $pinoAlternate, $pinoPrimary, theme, selected }) => {
        if (selected) {
            return `
                border: ${theme.spacing(2)} solid ${$pinoPrimary};
                background: ${$pinoAlternate};
            `;
        }

        return `
            border: ${theme.spacing(2)} solid ${palette.common.lightGrey};
            background: ${palette.common.white};
        `;
    }}

    ${({ $orientation, $isMBTOptimizationEnabled }) => {
        if ($orientation === 'vertical') {
            return `
                display: flex;
                flex-direction: column;
                padding: 1.5rem;

                header {
                    flex-direction: column;
                    margin-bottom: 1rem;

                    b {
                        margin-bottom: ${
                            $isMBTOptimizationEnabled ? '0' : '1rem'
                        };
                    }
                }

                footer {
                    flex: 1;
                    flex-direction: column;
                    justify-content: end;

                    div + div {
                        margin-top: 1rem;
                    }
                }
            `;
        }

        return `
            padding: 1.5rem;

            header {
                justify-content: space-between;
            }

            header + div {
                text-align: ${!$isMBTOptimizationEnabled && 'right'};
            }

            footer {
                justify-content: space-between;
                align-items: baseline;
            }
        `;
    }}

    position: relative;
    overflow: visible;
    color: ${palette.common.blue};
    border-radius: ${({ theme }) => theme.spacing(8)};

    &:hover {
        border: 0.2rem solid ${({ $pinoPrimary }) => $pinoPrimary};
        cursor: pointer;
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: 2.2rem 2.6rem;
    }

    ${({ theme }) => theme.breakpoints.down(468)} {
        ${({ $orientation, $numberOfElements }) => {
            if ($orientation === 'vertical') {
                let sidePadding = '1.5rem';

                if ($numberOfElements >= 3) {
                    sidePadding = '1rem';
                }

                return `
                    padding: 2.5rem ${sidePadding} 1.5rem ${sidePadding};
                `;
            }

            return '';
        }}
    }

    ${({ theme }) => theme.breakpoints.down(405)} {
        ${({ $orientation, $numberOfElements }) => {
            if ($orientation === 'vertical') {
                let sidePadding = '1.5rem';

                if ($numberOfElements >= 3) {
                    sidePadding = '0.75rem';
                }

                return `
                    padding: 2.5rem ${sidePadding} 1.5rem ${sidePadding};
                `;
            }

            return '';
        }}
    }
`;

export const StyledJoiningFeeText = styled('div')<{
    $gutterTop?: boolean;
}>`
    margin-top: ${({ $gutterTop }) => ($gutterTop ? '2rem' : '0')};
    font-size: 1rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.2rem;
    }

    ${({ theme }) => theme.breakpoints.down(386)} {
        margin-top: ${({ $gutterTop }) => ($gutterTop ? '4rem' : '0')};
    }
`;

export const StyledHorizontalRule = styled(HorizontalRule)<{
    $isMBTOptimizationEnabled: boolean;
}>`
    margin: 1rem 0;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: ${({ $isMBTOptimizationEnabled }) =>
                `${$isMBTOptimizationEnabled ? '1rem' : '1.5rem'}`}
            0;
    }
`;

export const StyledCardFooter = styled('footer')<{
    $isMBTOptimizationEnabled: boolean;
}>`
    display: flex;
    flex-direction: ${({ $isMBTOptimizationEnabled }) =>
        $isMBTOptimizationEnabled ? 'column' : 'row'};
    font-size: 1rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.2rem;
    }
`;

export const StyledPriceInnerContainer = styled('div')<{
    $orientation: CardOrientation;
}>`
    display: flex;
    flex-direction: ${({ $orientation }) =>
        $orientation === 'vertical' ? 'column' : 'row'};
`;

export const StyledPriceAndPostfixText = styled('div')<{
    $isMBTOptimizationEnabled: boolean;
}>`
    display: flex;
    flex-direction: ${({ $isMBTOptimizationEnabled }) =>
        $isMBTOptimizationEnabled && 'column'};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;
    }
`;

export const StyledPaypalText = styled('div')`
    display: block;
    flex-direction: column;
    font-weight: 700;
    font-size: 1.2rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        display: flex;
        text-align: right;
    }
`;
