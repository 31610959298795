import { palette } from '../../theme';
import { getPinoColor } from '../../utils/getPinoColors';
import { Icon } from '../Icon';
import { ProductHighlightPill } from '../ProductHighlightPill';
import { Spacing, SpacingSize } from '../Spacing';
import { getDataTestIdString } from './PinoProductOption.helpers';
import {
    StyledBoldText,
    StyledCard,
    StyledCardHeader,
    StyledCardFooter,
    StyledHorizontalRule,
    StyledJoiningFeeText,
    StyledPinoOptionButton,
    StyledPrice,
    StyledPriceContainer,
    StyledStrikethroughPrice,
    StyledPaypalText,
    StyledPriceInnerContainer,
    StyledBoldHeader,
    StyledPriceAndPostfixText,
} from './PinoProductOption.styled';
import { PinoProductOptionProperties } from './PinoProductOption.types';
import { getKickerPricePostfixText } from './utils';
import { ProductVariant } from '@tgg/common-types';
import { addOrdinalSuffix, decimalToPoundsAndPence } from '@tgg/util';

export const PinoProductOption = ({
    product,
    pinoProductSelected,
    onSelectProductOption,
    title,
    orientation = 'horizontal',
    numberOfElements,
    pill,
    showPaypalPayIn3,
    isMBTOptimizationEnabled = false,
}: PinoProductOptionProperties) => {
    const { alternate, contentColor } = getPinoColor(title);

    const squeezeBestValue = numberOfElements > 3;

    const {
        isKickerPriceInForce,
        kickerPriceDuration,
        kickerPriceMonthlyFee,
        value,
        frequency,
    } = product.price;

    const isKickerInUse =
        isKickerPriceInForce && kickerPriceDuration && kickerPriceMonthlyFee;

    const isFixedOrStudentProductVariant =
        product.variant === ProductVariant.Fixed ||
        product.variant === ProductVariant.Student;
    const isDayPassProductVariant = product.variant === ProductVariant.DayPass;
    const isFixedOrMonthlyProductVariant =
        product.variant === ProductVariant.Monthly ||
        product.variant === ProductVariant.Fixed;
    const isMonthlyProductVariant = product.variant === ProductVariant.Monthly;

    const showPricePostfix =
        isKickerInUse ||
        isFixedOrStudentProductVariant ||
        isMBTOptimizationEnabled ||
        isDayPassProductVariant;

    const dividerColor = pinoProductSelected
        ? palette.common.blue
        : palette.grey[100];

    const pinoButtonContent = pinoProductSelected ? (
        <Icon name="tick" size={18} />
    ) : (
        'select'
    );

    const finalPrice =
        product.variant === ProductVariant.Monthly ||
        product.variant === ProductVariant.DayPass
            ? decimalToPoundsAndPence(value, true)
            : decimalToPoundsAndPence(value, false);

    return (
        <StyledCard
            $pinoPrimary={contentColor}
            $pinoAlternate={alternate}
            $orientation={orientation}
            $numberOfElements={numberOfElements}
            variant="outlined"
            data-testid="product-option-card"
            data-ab-test={
                pinoProductSelected ? 'product-option-card-selected' : undefined
            }
            selected={pinoProductSelected}
            onClick={() => onSelectProductOption(product)}
            component="article"
            $isMBTOptimizationEnabled={
                isMBTOptimizationEnabled && isFixedOrMonthlyProductVariant
            }
        >
            {pill && (
                <ProductHighlightPill
                    {...{ squeeze: squeezeBestValue, ...pill }}
                />
            )}

            <StyledCardHeader
                $isMBTOptimizationEnabled={
                    isMBTOptimizationEnabled && isFixedOrMonthlyProductVariant
                }
            >
                <StyledBoldHeader
                    $isMBTOptimizationEnabled={
                        isMBTOptimizationEnabled &&
                        isFixedOrMonthlyProductVariant
                    }
                >
                    {product.variant === ProductVariant.Monthly && (
                        <>1 month rolling</>
                    )}
                    {isFixedOrStudentProductVariant && (
                        <>
                            {frequency.term}{' '}
                            {`months${
                                isMBTOptimizationEnabled ? ' saver' : ''
                            }`}
                        </>
                    )}
                    {isDayPassProductVariant && <>{frequency.term} day pass</>}
                </StyledBoldHeader>
                <StyledPriceContainer>
                    {isMBTOptimizationEnabled &&
                        isFixedOrMonthlyProductVariant &&
                        isKickerInUse && (
                            <StyledStrikethroughPrice>
                                {decimalToPoundsAndPence(
                                    kickerPriceMonthlyFee,
                                    true,
                                )}
                            </StyledStrikethroughPrice>
                        )}
                    {isMBTOptimizationEnabled &&
                        isFixedOrMonthlyProductVariant &&
                        !isKickerInUse && <Spacing size={SpacingSize.Medium} />}
                    <StyledPriceInnerContainer $orientation={orientation}>
                        {!isMBTOptimizationEnabled && isKickerInUse && (
                            <StyledStrikethroughPrice>
                                {decimalToPoundsAndPence(
                                    kickerPriceMonthlyFee,
                                    true,
                                )}
                            </StyledStrikethroughPrice>
                        )}
                        <StyledPriceAndPostfixText
                            $isMBTOptimizationEnabled={
                                isMBTOptimizationEnabled &&
                                isFixedOrMonthlyProductVariant
                            }
                        >
                            <StyledPrice $color={contentColor}>
                                {finalPrice}
                            </StyledPrice>

                            {showPricePostfix &&
                                getKickerPricePostfixText(
                                    kickerPriceDuration as number,
                                    product.variant,
                                    isMBTOptimizationEnabled,
                                )}
                        </StyledPriceAndPostfixText>
                    </StyledPriceInnerContainer>
                    {!isMonthlyProductVariant && showPaypalPayIn3 && (
                        <StyledPaypalText>
                            or PayPal pay in&nbsp;3
                        </StyledPaypalText>
                    )}
                </StyledPriceContainer>
            </StyledCardHeader>

            <StyledJoiningFeeText
                $gutterTop={
                    isMBTOptimizationEnabled &&
                    showPaypalPayIn3 &&
                    /* istanbul ignore next */ isMonthlyProductVariant
                }
            >
                {product.joiningFee ? (
                    <>
                        <StyledBoldText>
                            +{' '}
                            {decimalToPoundsAndPence(product.joiningFee, false)}{' '}
                        </StyledBoldText>
                        joining fee
                    </>
                ) : (
                    <>
                        <StyledBoldText>+ no </StyledBoldText>joining fee
                    </>
                )}
            </StyledJoiningFeeText>

            <StyledHorizontalRule
                color={dividerColor}
                $isMBTOptimizationEnabled={
                    isMBTOptimizationEnabled && isFixedOrMonthlyProductVariant
                }
            />

            <StyledCardFooter
                $isMBTOptimizationEnabled={
                    isMBTOptimizationEnabled && isFixedOrMonthlyProductVariant
                }
            >
                <div>
                    {isKickerInUse &&
                        (isMBTOptimizationEnabled ||
                            orientation === 'horizontal') && (
                            <>
                                <StyledBoldText>
                                    {decimalToPoundsAndPence(
                                        kickerPriceMonthlyFee,
                                    )}{' '}
                                </StyledBoldText>
                                after {addOrdinalSuffix(kickerPriceDuration)}{' '}
                                month
                            </>
                        )}
                    {product.variant === ProductVariant.Fixed && (
                        <>
                            equal to
                            <StyledBoldText>
                                {' '}
                                {decimalToPoundsAndPence(
                                    value / frequency.term,
                                )}
                            </StyledBoldText>
                            /month
                        </>
                    )}
                </div>
                <div>
                    <StyledPinoOptionButton
                        selected={pinoProductSelected}
                        buttonLocation="card"
                        title={title}
                        data-testid={getDataTestIdString(
                            product,
                            frequency.term,
                        )}
                        $isMBTOptimizationEnabled={
                            isMBTOptimizationEnabled &&
                            isFixedOrMonthlyProductVariant
                        }
                    >
                        {pinoButtonContent}
                    </StyledPinoOptionButton>
                </div>
            </StyledCardFooter>
        </StyledCard>
    );
};
