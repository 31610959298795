import {
    PinoMonthlyProduct,
    PinoProduct,
    ProductVariant,
} from '@tgg/common-types';

export const generateCTAHref = (
    productsAvailableAddons:
        | {
              [productId: string]: boolean;
          }
        | undefined,
    selectedProduct: PinoProduct,
    ctaHref: string,
): string => {
    if (selectedProduct.variant === ProductVariant.Fixed) {
        return ctaHref;
    }

    if (productsAvailableAddons?.[selectedProduct.id]) {
        return '/join-now/extras/';
    }

    return ctaHref;
};

export const getPinoOrientation = (
    numberOfProducts: number,
    isDesktop: boolean,
) =>
    (numberOfProducts > 1 && !isDesktop) || (numberOfProducts > 2 && isDesktop)
        ? 'vertical'
        : 'horizontal';

export const getProductsArray = (
    monthlyProducts: PinoMonthlyProduct,
    fixedProducts: PinoProduct[],
    isMBTOptimization: boolean,
) =>
    isMBTOptimization
        ? [
              monthlyProducts,
              ...fixedProducts.sort(
                  /* istanbul ignore next */
                  (a, b) => a.price.value - b.price.value,
              ),
          ]
        : [...fixedProducts];
