import { ProductVariant } from '@tgg/common-types';

export const getKickerPricePostfixText = (
    term: number,
    variant?: ProductVariant,
    isMBTOptimizationEnabled?: boolean,
) => {
    if (
        variant === ProductVariant.Fixed ||
        variant === ProductVariant.Student ||
        variant === ProductVariant.DayPass
    ) {
        return '/upfront';
    }
    if (variant === ProductVariant.Monthly && isMBTOptimizationEnabled) {
        return '/1st mo';
    }
    if (term === 1) {
        return '/1st month';
    }
    if (term > 1) {
        return `/${term} months`;
    }

    return '';
};
